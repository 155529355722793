@import './colors';
@import './typography';

$white:map-get($Neutrals, "N0");

$primary: map-get($Primary, "P300");
$secondary: map-get($Neutrals, "N0");
$success: map-get($Success, "G300");
$info: map-get($Primary, "P50");
$warning: map-get($Warning, "Y300");
$danger: map-get($Error, "R300");
$light: map-get($Neutrals, "N50");
$dark: map-get($Neutrals, "N900");

$container-max-widths: (
  sm: 95%,
  md: 95%,
  lg: 960px,
  xl: 1240px
) !default;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1366px) !default;