
/*jb-range*/
.jb-range {
    margin: 15px 0;
}

.jb-range-track {
    height: 4px;
    background: #efefef;
    .jb-range-fill-track {
        height: 4px;
        width: 100%;
        border-radius: 4px;
        align-self: center;
        .jb-range-thumb {
            width: 16px;
            height: 16px;
            background: #5468fd;
            border-radius: 50px;
            &:focus, &.dragging {
                box-shadow: none;
                outline: 0;
            }
        }
    }
}

.jb-range-labels {
    margin: 15px 0;
    font-size: 12px;
    font-weight: 500;
    .label {
        &:first-child {
            margin-right: 6px;
        }
        &:last-child {
            margin-left: 6px
        }
    }
}

.jb-filter-button {
    cursor: pointer;
    font-size: 11px;
    font-weight: 500;
    line-height: 1;
    padding: 3px 7px;
    border-radius: 4px;
    transition: all 0.2s;
    &.success {
        color: green;
    }
    &.light {
        color: #a7a7a7;
    }
    &:hover, &:focus {
        background: #efefefd9;
        transition: all 0.2s;
    }
}