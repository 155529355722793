.icon-Mail-Filled:before {
  content: "\e9ce";
}
.icon-Mail:before {
  content: "\e9cf";
}
.icon-Attach-Money:before {
  content: "\e9d2";
}
.icon-AlertNotif-Filled:before {
  content: "\e900";
}
.icon-AlertNotif:before {
  content: "\e901";
}
.icon-Arrow1-Down:before {
  content: "\e902";
}
.icon-Arrow1-Left:before {
  content: "\e903";
}
.icon-Arrow1-Right:before {
  content: "\e904";
}
.icon-Arrow1-Up:before {
  content: "\e905";
}
.icon-Arrow2-Down:before {
  content: "\e906";
}
.icon-Arrow2-Left:before {
  content: "\e907";
}
.icon-Arrow2-Right:before {
  content: "\e908";
}
.icon-Arrow2-Up:before {
  content: "\e909";
}
.icon-Arrow3-Down:before {
  content: "\e90a";
}
.icon-Arrow3-Left:before {
  content: "\e90b";
}
.icon-Arrow3-Right:before {
  content: "\e90c";
  color: #7b7b7b;
}
.icon-Arrow3-Up:before {
  content: "\e90d";
  color: #7b7b7b;
}
.icon-AssignmentTurnedIn-Filled:before {
  content: "\ea1b";
}
.icon-AssignmentTurnedIn:before {
  content: "\ea1c";
}
.icon-ArrowCircle-Down-Filled:before {
  content: "\e90e";
}
.icon-ArrowCircle-Down:before {
  content: "\e90f";
}
.icon-ArrowCircle-Left-Filled:before {
  content: "\e910";
  color: #7b7b7b;
}
.icon-ArrowCircle-Left:before {
  content: "\e911";
  color: #7b7b7b;
}
.icon-ArrowCircle-Right-Filled:before {
  content: "\e912";
}
.icon-ArrowCircle-Right:before {
  content: "\e913";
}
.icon-ArrowCircle-Up-Filled:before {
  content: "\e914";
  color: #7b7b7b;
}
.icon-ArrowCircle-Up:before {
  content: "\e915";
  color: #7b7b7b;
}
.icon-Badge:before {
  content: "\e9d0";
}
.icon-Badge-Filled:before {
  content: "\e9d1";
}
.icon-Box-Filled:before {
  content: "\ea1f";
}
.icon-Box:before {
  content: "\ea20";
}
.icon-Build-Filled:before {
  content: "\ea6a";
}
.icon-Build:before {
  content: "\ea6b";
}
.icon-ContractDelete-Filled:before {
  content: "\ea23";
}
.icon-ContractDelete:before {
  content: "\ea24";
}
.icon-Content .path1:before {
  content: "\ea80";
  color: rgb(237, 172, 65);
}
.icon-Content .path2:before {
  content: "\ea81";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Content .path3:before {
  content: "\ea82";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Content .path4:before {
  content: "\ea83";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-DefaultNotif-Filled:before {
  content: "\e916";
}
.icon-DefaultNotif:before {
  content: "\e917";
}
.icon-DraftOrders-Filled:before {
  content: "\ea60";
}
.icon-DraftOrders:before {
  content: "\ea61";
}
.icon-Dissatisfied-Filled:before {
  content: "\e918";
  color: #7b7b7b;
}
.icon-Dissatisfied:before {
  content: "\e919";
  color: #7b7b7b;
}
.icon-Dissatisfied1 .path1:before {
  content: "\ea84";
  color: rgb(237, 172, 65);
}
.icon-Dissatisfied1 .path2:before {
  content: "\ea85";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Dissatisfied1 .path3:before {
  content: "\ea86";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Dissatisfied1 .path4:before {
  content: "\ea87";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Draft-Filled:before {
  content: "\e9d3";
}
.icon-Draft:before {
  content: "\e9d4";
}
.icon-EditNotif-Filled:before {
  content: "\e91a";
}
.icon-EditNotif:before {
  content: "\e91b";
}
.icon-Forum-Filled:before {
  content: "\ea2b";
}
.icon-Forum:before {
  content: "\ea2c";
}
.icon-GroupAdd-Filled:before {
  content: "\ea2f";
}
.icon-GroupAdd:before {
  content: "\ea30";
}
.icon-ImportantNotif-Filled:before {
  content: "\e91c";
  color: #7b7b7b;
}
.icon-ImportantNotif:before {
  content: "\e91d";
  color: #7b7b7b;
}
.icon-Invisible-Filled:before {
  content: "\e91e";
}
.icon-Invisible:before {
  content: "\e91f";
}
.icon-Inbox-Filled:before {
  content: "\ea32";
}
.icon-Inbox:before {
  content: "\ea33";
}
.icon-NewNotif-Filled:before {
  content: "\e920";
}
.icon-NewNotif:before {
  content: "\e921";
}
.icon-NotifOff:before {
  content: "\ea66";
}
.icon-NotifOff:before {
  content: "\ea67";
}
.icon-Satisfied-Filled:before {
  content: "\e924";
  color: #7b7b7b;
}
.icon-Satisfied:before {
  content: "\e925";
  color: #7b7b7b;
}
.icon-Satisfied1 .path1:before {
  content: "\ea7c";
  color: rgb(237, 172, 65);
}
.icon-Satisfied1 .path2:before {
  content: "\ea7d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Satisfied1 .path3:before {
  content: "\ea7e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Satisfied1 .path4:before {
  content: "\ea7f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Star-Filled:before {
  content: "\e926";
}
.icon-Star-Half:before {
  content: "\e927";
}
.icon-Star:before {
  content: "\e928";
}
.icon-Visible-Filled:before {
  content: "\e929";
}
.icon-Visible:before {
  content: "\e92a";
}
.icon-AccountBalance-Filled:before {
  content: "\e92b";
}
.icon-AccountBalance:before {
  content: "\e92c";
}
.icon-AccountBalanceWallet-Filled:before {
  content: "\ea70";
}
.icon-AccountBalanceWallet:before {
  content: "\ea71";
}
.icon-AccountCircle-Filled:before {
  content: "\e92d";
}
.icon-AccountCircle:before {
  content: "\e92e";
}
.icon-AddBox-Filled:before {
  content: "\e92f";
}
.icon-AddBox:before {
  content: "\e930";
}
.icon-AddCircle-Filled:before {
  content: "\e931";
}
.icon-AddCircle:before {
  content: "\e932";
}
.icon-AddPhoto-Filled:before {
  content: "\e933";
}
.icon-AddPhoto:before {
  content: "\e934";
}
.icon-Article-Filled:before {
  content: "\e935";
}
.icon-Article:before {
  content: "\e936";
}
.icon-AssuredWorkload-Filled:before {
  content: "\e937";
}
.icon-AssuredWorkload:before {
  content: "\e938";
}
.icon-BorderColor-Filled:before {
  content: "\e939";
}
.icon-BorderColor:before {
  content: "\e93a";
}
.icon-CalendarMonth-Filled:before {
  content: "\e93b";
  color: #7b7b7b;
}
.icon-CalendarMonth:before {
  content: "\e93c";
  color: #7b7b7b;
}
.icon-Call-Filled:before {
  content: "\e93d";
}
.icon-Call:before {
  content: "\e93e";
}
.icon-CompareArrows:before {
  content: "\ea8c";
}
.icon-Cancel-Filled:before {
  content: "\e93f";
}
.icon-Cancel:before {
  content: "\e940";
}
.icon-Chat-Filled:before {
  content: "\e941";
}
.icon-Chat:before {
  content: "\e942";
}
.icon-ChatBubble-Filled:before {
  content: "\e943";
}
.icon-ChatBubble:before {
  content: "\e944";
}
.icon-CircleCheck-Filled:before {
  content: "\e945";
}
.icon-CircleCheck:before {
  content: "\e946";
}
.icon-CircleLightbulb-Filled:before {
  content: "\e947";
}
.icon-CircleLightbulb:before {
  content: "\e948";
}
.icon-CircleNotifications-Filled:before {
  content: "\e949";
}
.icon-Category-Filled:before {
  content: "\e9ef";
}
.icon-Category:before {
  content: "\e9f0";
}
.icon-CircleNotifications:before {
  content: "\e94a";
}
.icon-CloudOff-Filled:before {
  content: "\e94b";
  color: #7b7b7b;
}
.icon-CloudOff:before {
  content: "\e94c";
  color: #7b7b7b;
}
.icon-CreditCard-Filled:before {
  content: "\e94d";
}
.icon-CreditCard:before {
  content: "\e94e";
}
.icon-CreditCardGear-Filled:before {
  content: "\ea25";
}
.icon-CreditCardGear:before {
  content: "\ea26";
}
.icon-Currency-Exchange:before {
  content: "\ea52";
}
.icon-SyncAlt:before {
  content: "\ea8a";
}
.icon-DarkMode-Filled:before {
  content: "\e94f";
}
.icon-DarkMode:before {
  content: "\e950";
}
.icon-Delete-Filled:before {
  content: "\e951";
}
.icon-Delete:before {
  content: "\e952";
}
.icon-Description-Filled:before {
  content: "\e953";
}
.icon-Description:before {
  content: "\e954";
}
.icon-Edit-Filled:before {
  content: "\e955";
}
.icon-Edit:before {
  content: "\e956";
}
.icon-Emergency-Filled:before {
  content: "\e957";
}
.icon-Emergency:before {
  content: "\e958";
}
.icon-Error-Filled:before {
  content: "\e959";
}
.icon-Error:before {
  content: "\e95a";
}
.icon-Event-Filled:before {
  content: "\e95b";
}
.icon-Event:before {
  content: "\e95c";
}
.icon-FactCheck-Filled:before {
  content: "\ea6c";
}
.icon-FactCheck:before {
  content: "\ea6d";
}
.icon-Flag-Filled:before {
  content: "\e95d";
}
.icon-Flag:before {
  content: "\e95e";
}
.icon-FlagBackslash-Filled:before {
  content: "\e9e0";
}
.icon-FlagBackslash:before {
  content: "\e9e1";
}
.icon-FormatQuote-Filled:before {
  content: "\e95f";
}
.icon-FormatQuote:before {
  content: "\e960";
}
.icon-FreeCancellation-Filled:before {
  content: "\ea2d";
}
.icon-FreeCancellation:before {
  content: "\ea2e";
}
.icon-Help-Filled:before {
  content: "\e961";
}
.icon-Help:before {
  content: "\e962";
}
.icon-HintBulb-Filled:before {
  content: "\e963";
}
.icon-HintBulb:before {
  content: "\e964";
}
.icon-Home-Filled:before {
  content: "\e965";
}
.icon-Home:before {
  content: "\e966";
}
.icon-Home-Repair-Service-Filled:before {
  content: "\e9d9";
}
.icon-HomeRepairService:before {
  content: "\e9da";
}
.icon-Handyman-Filled:before {
  content: "\ea07";
}
.icon-Handyman:before {
  content: "\ea08";
}
.icon-HourglassBottom:before {
  content: "\ea31";
}
.icon-HourglassTop:before {
  content: "\ea8b";
}
.icon-Image-Filled:before {
  content: "\e967";
}
.icon-Image:before {
  content: "\e968";
}
.icon-Info-Filled:before {
  content: "\e969";
}
.icon-Info:before {
  content: "\e96a";
}
.icon-Inventory-Filled:before {
  content: "\e96b";
}
.icon-Inventory:before {
  content: "\e96c";
}
.icon-Keep-Filled:before {
  content: "\ea34";
}
.icon-Keep:before {
  content: "\ea35";
}
.icon-KeepOff-Filled:before {
  content: "\ea36";
}
.icon-KeepOff:before {
  content: "\ea37";
}
.icon-Label-Filled:before {
  content: "\e96d";
}
.icon-Label:before {
  content: "\e96e";
}
.icon-Location-Filled:before {
  content: "\e96f";
}
.icon-Location:before {
  content: "\e970";
}
.icon-Map-Filled:before {
  content: "\e971";
}
.icon-Map:before {
  content: "\e972";
}
.icon-MilitaryTech-Filled:before {
  content: "\e973";
}
.icon-MilitaryTech:before {
  content: "\e974";
}
.icon-MarkUnreadMailbox-Filled:before {
  content: "\ea3c";
}
.icon-MarkUnreadMailbox:before {
  content: "\ea3d";
}
.icon-Monetization-Filled:before {
  content: "\e975";
}
.icon-Monetization:before {
  content: "\e976";
}
.icon-Morning-Filled:before {
  content: "\e977";
}
.icon-Morning:before {
  content: "\e978";
}
.icon-MyLocation-Filled:before {
  content: "\e979";
}
.icon-MyLocation:before {
  content: "\e97a";
}
.icon-ManageAccounts-Filled:before {
  content: "\ea3a";
}
.icon-ManageAccounts:before {
  content: "\ea3b";
}
.icon-NearMe-Filled:before {
  content: "\e97b";
}
.icon-NearMe:before {
  content: "\e97c";
}
.icon-Linkedin-ColorfulBg .path1:before {
  content: "\ea99";
  color: rgb(0, 119, 181);
}
.icon-Linkedin-ColorfulBg .path2:before {
  content: "\ea9a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Linkedin-ColorfulBg .path3:before {
  content: "\ea9b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Linkedin-ColorfulBg .path4:before {
  content: "\ea9c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Facebook-ColorfulBg .path1:before {
  content: "\eaa0";
  color: rgb(25, 119, 243);
}
.icon-Facebook-ColorfulBg .path2:before {
  content: "\eaa1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-WhatsApp-ColorfulBg .path1:before {
  content: "\eaa7";
  color: rgb(39, 208, 69);
}
.icon-WhatsApp-ColorfulBg .path2:before {
  content: "\eaa8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-WhatsApp-ColorfulBg .path3:before {
  content: "\eaa9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-WhatsApp-ColorfulBg .path4:before {
  content: "\eaaa";
  margin-left: -1em;
  color: rgb(39, 208, 69);
}
.icon-WhatsApp-ColorfulBg .path5:before {
  content: "\eaab";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-X-BlackBg:before {
  content: "\ea93";
  color: rgb(255, 255, 255);
}
.icon-PDF-Filled:before {
  content: "\e97d";
  color: #7b7b7b;
}
.icon-PDF:before {
  content: "\e97e";
  color: #7b7b7b;
}
.icon-Podcasts:before {
  content: "\ea55";
}
.icon-PlayCircle-Filled:before {
  content: "\e97f";
  color: #7b7b7b;
}
.icon-PlayCircle:before {
  content: "\e980";
  color: #7b7b7b;
}
.icon-Print-Filled:before {
  content: "\e981";
}
.icon-Print:before {
  content: "\e982";
}
.icon-Privacy-Filled:before {
  content: "\e983";
}
.icon-Privacy:before {
  content: "\e984";
}
.icon-PhotoLibrary-Filled:before {
  content: "\ea68";
}
.icon-PhotoLibrary:before {
  content: "\ea69";
}
.icon-PriceCheck:before {
  content: "\e9dc";
}
.icon-Report-Filled:before {
  content: "\e985";
}
.icon-Report:before {
  content: "\e986";
}
.icon-Reviews-Filled:before {
  content: "\e987";
}
.icon-Reviews:before {
  content: "\e988";
}
.icon-ReceiptLong-Filled:before {
  content: "\ea40";
}
.icon-ReceiptLong:before {
  content: "\ea41";
}
.icon-RightPanelClose-Filled:before {
  content: "\ea44";
}
.icon-RightPanelClose:before {
  content: "\ea45";
}
.icon-RightPanelOpen-Filled:before {
  content: "\ea46";
}
.icon-OpenInNew:before {
  content: "\ea54";
}
.icon-RightPanelOpen:before {
  content: "\ea47";
}
.icon-RequestQuote-Filled:before {
  content: "\ea42";
}
.icon-RequestQuote:before {
  content: "\ea43";
}
.icon-ScanWiFi-Filled:before {
  content: "\e989";
  color: #7b7b7b;
}
.icon-ScanWiFi:before {
  content: "\e98a";
  color: #7b7b7b;
}
.icon-ScanDelete-Filled:before {
  content: "\ea48";
}
.icon-ScanDelete:before {
  content: "\ea49";
}
.icon-Schedule-Filled:before {
  content: "\e98b";
}
.icon-Schedule:before {
  content: "\e98c";
}
.icon-Send-Filled:before {
  content: "\e98d";
}
.icon-Send:before {
  content: "\e98e";
}
.icon-Settings-Filled:before {
  content: "\e98f";
}
.icon-Settings:before {
  content: "\e990";
}
.icon-Share-Filled:before {
  content: "\e991";
}
.icon-Share:before {
  content: "\e992";
}
.icon-Smartphone-Filled:before {
  content: "\e993";
  color: #7b7b7b;
}
.icon-Smartphone:before {
  content: "\e994";
}
.icon-SMSFailed-Filled:before {
  content: "\e995";
}
.icon-SMSFailed:before {
  content: "\e996";
}
.icon-SunriseSunset-Filled:before {
  content: "\e997";
}
.icon-SunriseSunset:before {
  content: "\e998";
}
.icon-ServiceToolbox-Filled:before {
  content: "\ea4a";
}
.icon-ServiceToolbox:before {
  content: "\ea4b";
}
.icon-ServiceToolboxAdd-Filled:before {
  content: "\ea4c";
}
.icon-ServiceToolboxAdd:before {
  content: "\ea4d";
}
.icon-ServiceToolboxEdit-Filled:before {
  content: "\ea4e";
}
.icon-ServiceToolboxEdit:before {
  content: "\ea4f";
}
.icon-ThumbDown-Filled:before {
  content: "\e999";
}
.icon-ThumbDown:before {
  content: "\e99a";
}
.icon-ThumbUp-Filled:before {
  content: "\e99b";
}
.icon-ThumbUp:before {
  content: "\e99c";
}
.icon-User-Filled:before {
  content: "\e99d";
}
.icon-User:before {
  content: "\e99e";
}
.icon-Update:before {
  content: "\ea59";
}
.icon-Verified-Filled:before {
  content: "\e99f";
}
.icon-Verified:before {
  content: "\e9a1";
}
.icon-Videocam-Filled:before {
  content: "\e9a2";
}
.icon-Videocam:before {
  content: "\e9a3";
}
.icon-VeryDissatisfied .path1:before {
  content: "\ea74";
  color: rgb(237, 172, 65);
}
.icon-VeryDissatisfied .path2:before {
  content: "\ea75";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-VeryDissatisfied .path3:before {
  content: "\ea76";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-VeryDissatisfied .path4:before {
  content: "\ea77";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-VerySatisfied .path1:before {
  content: "\ea78";
  color: rgb(237, 172, 65);
}
.icon-VerySatisfied .path2:before {
  content: "\ea79";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-VerySatisfied .path3:before {
  content: "\ea7a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-VerySatisfied .path4:before {
  content: "\ea7b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Work-Filled:before {
  content: "\e9a4";
}
.icon-Work:before {
  content: "\e9a5";
}
.icon-Workspaces-Filled:before {
  content: "\ea50";
}
.icon-Workspaces:before {
  content: "\ea51";
}
.icon-Active:before {
  content: "\e9a6";
}
.icon-Add:before {
  content: "\e9a7";
}
.icon-Alternate-Email:before {
  content: "\e9a8";
}
.icon-Attach-a-file:before {
  content: "\e9a9";
}
.icon-Block:before {
  content: "\e9aa";
}
.icon-Bullet-List:before {
  content: "\e9ab";
}
.icon-Cached:before {
  content: "\e9ac";
}
.icon-Check:before {
  content: "\e9ad";
}
.icon-Checklist:before {
  content: "\e9db";
}
.icon-Clear-all:before {
  content: "\e9ae";
}
.icon-Close:before {
  content: "\e9af";
}
.icon-Done-All:before {
  content: "\e9b0";
}
.icon-Done:before {
  content: "\e9b1";
}
.icon-Donut-Large:before {
  content: "\e9b2";
}
.icon-File-download:before {
  content: "\e9b3";
}
.icon-File-upload:before {
  content: "\e9b4";
}
.icon-Filter-List:before {
  content: "\e9b5";
}
.icon-Hail:before {
  content: "\e9b6";
  color: #7b7b7b;
}
.icon-History:before {
  content: "\e9b7";
}
.icon-Hourglass-Empty:before {
  content: "\e9b8";
}
.icon-Inactive:before {
  content: "\e9b9";
  color: #7b7b7b;
}
.icon-Jump-to-Top:before {
  content: "\e9ba";
  color: #7b7b7b;
}
.icon-Language:before {
  content: "\e9bb";
}
.icon-Link-Off:before {
  content: "\e9bc";
}
.icon-Link:before {
  content: "\e9bd";
}
.icon-Login:before {
  content: "\e9be";
}
.icon-Logout:before {
  content: "\ea58";
}
.icon-Money:before {
  content: "\e9bf";
}
.icon-More---Horizontal:before {
  content: "\e9c0";
}
.icon-More---Vertical:before {
  content: "\e9c1";
}
.icon-Password:before {
  content: "\e9c2";
  color: #7b7b7b;
}
.icon-TrendingUp:before {
  content: "\ea89";
}
.icon-Refresh:before {
  content: "\e9c3";
}
.icon-Remove:before {
  content: "\e9c4";
  color: #7b7b7b;
}
.icon-SearchOff:before {
  content: "\ea57";
}
.icon-Search:before {
  content: "\e9c5";
}
.icon-Security:before {
  content: "\e9c6";
}
.icon-Sort:before {
  content: "\e9c7";
  color: #7b7b7b;
}
.icon-Sync-Problem:before {
  content: "\e9c8";
}
.icon-Sync:before {
  content: "\e9c9";
}
.icon-Task-Alt:before {
  content: "\e9ca";
}
.icon-Title:before {
  content: "\e9cb";
}
.icon-Tune:before {
  content: "\e9cc";
  color: #7b7b7b;
}
.icon-Task-Filled:before {
  content: "\e9d5";
}
.icon-Task:before {
  content: "\e9d6";
}
.icon-WiFi-Off:before {
  content: "\e9cd";
  color: #7b7b7b;
}
.icon-Wand:before {
  content: "\e9dd";
}
