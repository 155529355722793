@import 'utils-styles/jb-buttons.scss';
@import 'utils-styles/jb-alerts.scss';
@import 'utils-styles/jb-range.scss';

@import "../variables";

.jb-map-marker {
  width: 40px;
  height: 40px;
}

.redux-toastr .toastr div {
  font-size: 12px;
}

.redux-toastr .toastr .close-toastr span {
  font-size: 14px;
}

/*other spans*/

.pill-span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 500;
  width: 72px;
  padding: 4px 10px;
  border-radius: 5px;
  line-height: 14px;
  color: #3f3f3f;
  background: #3f3f3f14;
}

.pill-success {
  color: #259e54;
  background: #259e541c;
}

/*jt tabs*/
.jt-tabs {
  height: 60px;
  padding: 0 25px;

  display: flex;
  flex-direction: row;
}

.jt-tabs .jt-tab-item {
  position: relative;
  display: inline-flex;
  height: 100%;
  padding: 0 15px;
  align-items: center;
  color: #4D576A;
  margin-right: 10px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s;
}

.jt-tabs .jt-tab-item:hover,
.jt-tabs .jt-tab-item.active {
  text-decoration: none;
  color: $primary;
  font-weight: 600;
  transition: all 0.2s;
}

.jt-tabs .jt-tab-item:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: transparent;
  left: 0;
  transition: all 0.2s;
}

.jt-tabs .jt-tab-item.active:after {
  background: $primary;
  transition: all 0.2s;
}

.jt-tabs-body {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 60px);
  overflow: auto;
}

@media(max-width: 767px) {
  .jt-tabs-body {
    padding: 15px 20px;
  }
}

@media(min-width: 768px) {
  .jt-tabs-body {
    padding: 20px 25px;
  }
}

/*jb tooltip*/
.jb-tooltip {
  display: inline-flex;
  margin: 0 4px;
  align-items: center;
  justify-content: center;
}

.jb-tooltip .icon {
  font-size: 16px;
  display: inline-block;
}

/* absolute-label */
.absolute-label {
  position: relative;

  &:before,
  &:after {
    position: absolute;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-5px);
    transition: all 0.2s;
  }

  &:after {
    content: attr(data-label);
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -20px;
    background: #2B3240;
    color: white;
    font-size: 13px;
    padding: 4px 10px;
    line-height: 1;
    border-radius: 6px;
    white-space: nowrap;
  }

  &:hover,
  &:focus {

    &:before,
    &:after {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
      transition: all 0.2s;
    }
  }
}

.text-content {
  word-break: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  color: #1a1848cc;
  font-size: 12px;
}