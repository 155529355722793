/* fade button*/
.jb-fade-btn {
  display: block;
  height: 38px;
  line-height: 1;
  padding: 6px 16px;
  background: #efefef;
  color: #333;
  border-radius: 6px;
  border: 0;
  font-size: 0.75rem;
  font-weight: 500;
  text-decoration: none !important;
  transition: all 0.2s;
  &:hover, &:focus {
    background: #d8d8d8;
    color: #4c4c4c;
    outline: 0;
    text-decoration: none;
    transition: all 0.2s;
  }
  svg { font-size: 13px;}
  .btn-icon {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background: #5468fd2e;
    color: #4153dd;
  }
}
.jb-fade-primary {
  background: #dcdef3ba;
  color: $primary;
  &:hover, &:focus {
    background: #5468fd4a;
    color: $primary;
  }
}
.jb-fade-success {
  background: #00800026;
  color: $success;
  &:hover, &:focus {
    background: #00800042;
    color: $success;
  }
}
.jb-fade-danger {
  background: #ff004117;
  color: $danger;
  &:hover, &:focus {
    background: #ff004129;
    color: $danger;
  }
}
.jb-fade-warning {
  background: rgba(243, 176, 4, 0.1);
  color: map-get($Warning, "Y300");;
  &:hover, &:focus {
    background: rgba(243, 176, 4, 0.1);
    color: map-get($Warning, "Y300");;
  }
}

/* pill btn */
.jb-pill-btn {
  font-size: 12px;
  font-weight: 500;
  color: #4D576A;
  background: transparent;
  padding: 4px 10px;
  border-radius: 5px;
  text-decoration: none !important;
  transition: all 0.2s;
  &:hover, &:focus, &.active {
    color: map-get($Neutrals, "N900");
    background: #D5D6D9;
    transition: all 0.2s;
  }
}


/* link btn */
.jb-btn-link {
  background: transparent;
  color: #989898;
  &:hover, &:focus {
    background: transparent !important;
  }
}

.jb-btn-small { height: 30px; }
.jb-round-btn { border-radius: 50px !important; }
.jb-fit-btn { width: 100%; }

/* jb btn */
.jb-btn {
  display: block;
  width: 100%;
  border: 0;
  padding: 9px 10px;
  border-radius: 6px;
  font-weight: 500;
  background: #efefef;
  color: #333;
  outline: 0;
  text-decoration: none !important;
  transition: all 0.2s;
  &:hover, &:focus {
    background: #dcdcdc;
    color: #333;
    outline: 0;
    transition: all 0.2s;
  }
}
.jb-btn-primary {
  background: $primary;
  color: #fff;
  &:hover, &:focus {
    background: #495ce6;
    color: #fff;
  }
}
.jb-btn-success {
  background: #38c88a;
  color: #fff;
  &:hover, &:focus {
    background: #2fb47b;
    color: #fff;
  }
}
.jb-btn-danger {
  background: #ee8282;
  color: #fff;
  &:hover, &:focus {
    background: #e67373;
    color: #fff;
  }
}
.jb-btn-warning {
  background: $warning;
  color: #fff;
  &:hover, &:focus {
    background: #e47f4a;
    color: #fff;
  }
}
.jb-btn-facebook {
  background: #345D9F;
  color: #fff;
  &:hover, &:focus {
    background: #264a84;
    color: #fff;
  }
}
.jb-btn-twitter {
  background: #25AFF4;
  color: #fff;
  &:hover, &:focus {
    background: #1c99d8;
    color: #fff;
  }
}
.jb-btn-linkedin {
  background: #007DBA;
  color: #fff;
  &:hover, &:focus {
    background: #046a9c;
    color: #fff;
  }
}

/* border btn */
.jb-border-btn {
  width: 100%;
  border: 0;
  padding: 9px 10px;
  border-radius: 6px;
  font-weight: 500;
  background: transparent;
  color: #333;
  border: 1px solid #333;
  outline: 0;
  transition: all 0.2s;
}
.jb-border-btn:hover,
.jb-border-btn:focus {
  background: #333;
  color: #fff;
  outline: 0;
  transition: all 0.2s;
}
.round-btn { border-radius: 50px !important; }

.jb-border-primary {
  border-color: $primary;
  color: $primary;
}
.jb-border-primary:hover,
.jb-border-primary:focus {
  background: #495ce6;
  border-color: $primary;
  color: #fff;
}
.jb-border-danger {
  border-color: #ee8282;
  color: #ee8282;
}
.jb-border-danger:hover,
.jb-border-danger:focus {
  background: #ee8282;
  border-color: #ee8282;
  color: #fff;
}


.jb-circle-btn {
  width: 30px;
  height: 30px;
  font-size: 21px;
  cursor: pointer;
  padding: 0;
  border-radius: 50px;
}