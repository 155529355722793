@import '../../variables';

.jb-alert {
    width: 100%;
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    border-top: 1px solid #717171;
    padding: 15px 14px;
    margin-bottom: 15px;

    @media(max-width: 767px) {
        align-items: baseline;
        flex-direction: column;

        .alert-body {
            align-items: center;
            width: 100%;
            margin-bottom: 6px;
        }
    }

    @media(min-width: 768px) {
        align-items: center;
        flex-direction: row;

        .alert-body {
            align-items: center;
        }
    }

    .alert-body {
        display: flex;

        .alert-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            margin-right: 10px;
            border-radius: 50px;
            font-size: 20px;
            background: #71717130;
            color: #717171;
            flex-shrink: 0;
        }

        .alert-details {
            .alert-title {
                font-size: 14px;
                margin-bottom: 4px;
                width: 100%;
            }

            .alert-date {
                font-size: 12px;
                color: #000000;
                opacity: 0.6;
            }

            .alert-text {
                margin: 0;
                font-size: 14px;
                color: map-get($Neutrals, "N900");
            }
        }
    }

    .alert-action {
        margin-left: auto;
        flex-shrink: 0;

        .btn {
            background-color: rgba(255, 67, 67, 0.05);
            color: map-get($Error, 'R300');
            margin-left: 16px;
            &:hover {
            color: map-get($Error, 'R400');

            }
        }

        .amount-view {
            display: flex;
            align-items: baseline;

            .currency {
                font-size: 11px;
                font-weight: 500;
                color: #5468fd;
                margin-right: 2px
            }

            .amount {
                font-size: 16px;
                color: #5468fd;
                font-weight: 500;
            }
        }
    }

    &.alert-primary {
        border-top: 1px solid #5468fd;

        .alert-icon {
            background: #5468fd29;
            color: #5468fd;
        }
    }

    &.alert-danger {
        background-color: #fff;
        border-top: 1px solid #FF0041;

        .alert-icon {
            background: rgba(255, 0, 65, 0.1);
            color: #FF0041;
        }
    }

    &.alert-warning {
        border-top: 1px solid #fb8d50;

        .alert-icon {
            background: #e0ad271a;
            color: #fb8d50;
        }
    }
}