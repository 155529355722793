// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'global.scss';

@import 'panel.scss';


html {
    scrollbar-color: #00000021 transparent;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}


::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #00000021;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #00000031;
}

a,
a:hover {
    text-decoration: none;
}

/*flex-style*/
.flex-center {
    display: flex !important;
    align-items: center;
}

.flex-end {
    display: flex !important;
    align-items: flex-end;
}

.flex-justify {
    display: flex !important;
    justify-content: center;
}

.flex-both-center {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.flex-left {
    margin-right: auto;
}

.flex-right {
    margin-left: auto;
}

.flex-bottom {
    margin-top: auto;
}

.flex-cover {
    display: flex;
    width: 100%;
    height: 100%;
}

.flex-inline-center {
    display: inline-flex;
    align-items: center;
}

.flex-inline-both-center {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.both-center {
    align-items: center;
    justify-content: center;
}

.flex-column-center {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-center {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.btn-lg {
    padding: 0.75rem 1rem;
}

.btn-warning {
    color: #fff !important;
}

.btn-info,
.btn-secondary {
    color: map-get($Primary, 'P300') !important;
}
.btn-secondary {
    border-color: map-get($Primary, 'P50');;
    .spinner{
        border-color: map-get($Primary, 'P300');
    }
}

.react-viewer {
    .react-viewer-close>i {
        top: 11px;
    }

    .react-viewer-toolbar {
        margin-bottom: 20px !important;
    }

    .react-viewer-toolbar {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }
}


.MuiPickersCalendarHeader-labelContainer{
    max-height: 40px !important;
}

.css-ity0ql-MuiButtonBase-root-MuiButton-root{
    height: 35px !important;
}

.css-1ni8tcu{
    height: 35px !important;
}

@media screen and (max-width: 900px) {
    .css-ity0ql-MuiButtonBase-root-MuiButton-root{
        height: 40px !important;
    }
.css-1ni8tcu{
        height: 40px !important;
    }
   }