.auth-message {
  background: #6aeaa147;
  border: 1px solid #6aeaa180;
  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  color: #2d2d2d;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  position: relative;
  margin-top: -20px;
}

.css-6wwd73-MuiAutocomplete-noOptions {
  display: none !important;
}

// .css-q97m89 {
//   padding: 8px 14px !important;
// }