$font-family-base: "DMSans", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$typo-dsp-xl-large: (
  "size": 42px,
  "height": 44px,
  "weight": 400,
);
$typo-dsp-xl-small: (
  "size": 27px,
  "height": 36px,
  "weight": 400,
);

$typo-dsp-lg-large: (
  "size": 28px,
  "height": 32px,
  "weight": 400,
);
$typo-dsp-lg-small: (
  "size": 24px,
  "height": 28px,
  "weight": 400,
);

$typo-dsp-md-large: (
  "size": 26px,
  "height": 32px,
  "weight": 400,
);
$typo-dsp-md-small: (
  "size": 21px,
  "height": 28px,
  "weight": 400,
);

$typo-dsp-sm-large: (
  "size": 20px,
  "height": 24px,
  "weight": 400,
);
$typo-dsp-sm-small: (
  "size": 16px,
  "height": 24px,
  "weight": 400,
);

$typo-heading-large: (
  "size": 16px,
  "height": 24px,
  "weight": 400,
);
$typo-heading-small: (
  "size": 17px,
  "height": 24px,
  "weight": 400,
);

$typo-sub-heading-large: (
  "size": 12px,
  "height": 16px,
  "weight": 600,
);
$typo-sub-heading-small: (
  "size": 13px,
  "height": 16px,
  "weight": 600,
);

$typo-body-large: (
  "size": 14px,
  "height": 20px,
  "weight": 400,
);
$typo-body-small: (
  "size": 15px,
  "height": 20px,
  "weight": 400,
);

$typo-caption-large: (
  "size": 12px,
  "height": 16px,
  "weight": 400,
);
$typo-caption-small: (
  "size": 13px,
  "height": 20px,
  "weight": 400,
);

$typo-weights: (
  "thin": 100,
  "normal": 200,
  "bold": 400,
  "black": 600,
);

$typo-heading0-large: (
  "size": 42px,
  "height": 57px,
  "weight": 700,
);
$typo-heading0-small: (
  "size": 27px,
  "height": 36px,
  "weight": 700,
);

$typo-heading1-large: (
  "size": 28px,
  "height": 35px,
  "weight": 500,
);
$typo-heading1-small: (
  "size": 20px,
  "height": 24px,
  "weight": 600,
);

$typo-heading2-large: (
  "size": 27px,
  "height": 36px,
  "weight": 700,
);
$typo-heading2-small: (
  "size": 24px,
  "height": 32px,
  "weight": 700,
);

$typo-heading3-large: (
  "size": 24px,
  "height": 32px,
  "weight": 700,
);
$typo-heading3-small: (
  "size": 20px,
  "height": 24px,
  "weight": 600,
);

$typo-heading4-large: (
  "size": 20px,
  "height": 24px,
  "weight": 600,
);
$typo-heading4-small: (
  "size": 16px,
  "height": 24px,
  "weight": 600,
);

$typo-sub-heading1-large: (
  "size": 16px,
  "height": 24px,
  "weight": 600,
);
$typo-sub-heading1-small: (
  "size": 14px,
  "height": 24px,
  "weight": 600,
);

$typo-sub-heading2-large: (
  "size": 14px,
  "height": 24px,
  "weight": 600,
);
$typo-sub-heading2-small: (
  "size": 13px,
  "height": 16px,
  "weight": 500,
);

$typo-sub-heading3-large: (
  "size": 13px,
  "height": 16px,
  "weight": 500,
);
$typo-sub-heading3-small: (
  "size": 12px,
  "height": 16px,
  "weight": 500,
);

$typo-body1-large: (
  "size": 18px,
  "height": 25px,
  "weight": 400,
);
$typo-body1-small: (
  "size": 14px,
  "height": 25px,
  "weight": 400,
);

$typo-body2-large: (
  "size": 16px,
  "height": 24px,
  "weight": 400,
);
$typo-body2-small: (
  "size": 14px,
  "height": 25px,
  "weight": 400,
);

$typo-body3-large: (
  "size": 14px,
  "height": 25px,
  "weight": 400,
);
$typo-body3-small: (
  "size": 13px,
  "height": 16px,
  "weight": 400,
);

$typo-caption1-large: (
  "size": 12px,
  "height": 16px,
  "weight": 400,
);

$typo-caption2-large: (
  "size": 12px,
  "height": 16px,
  "weight": 500,
);

$typo-weights: (
  "thin": 200,
  "normal": 400,
  "bold": 500,
  "black": 600,
);
